import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EnComponent } from './components/en/en.component';
import { FrComponent } from './components/fr/fr.component';


const routes: Routes = [
  {path: '', component: EnComponent},
  {path: 'fr', component: FrComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }