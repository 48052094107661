<div class="travel-1">
    <div class="coming-soon-area">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="coming-soon-content">
                        <div class="logo">
                            <a routerLink="#"><img src="assets/img/logo.png" alt="Art of Travel"></a>
                        </div>
                        
                        <h1><span>Bientôt </span> Disponible</h1>
                        <p>Art of Travel est une agence de voyages et d'événements qui conçoit et organise tous vos voyages à travers le monde et vos événements culturels, sportifs et de divertissement en Tunisie.  </p>
                        <div class="btn-box">
                            <a class="btn btn-primary notify-btn" href="tel:+21622259896">Contactez-Nous</a>
                            <button class="btn btn-light get-more-info-btn" (click)="toggleClass()">A propos</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="footer-area">
            <div class="container pb-3">
                <ul>
                    <li><a style="border:none;" routerLink="/fr" ><img style="width: 100%; height:auto;" src="assets/img/fr.png" alt="Art of Travel"></a></li>
                    <li><a style="border:none;" routerLink="/" ><img  style="width: 100%; height:auto;" src="assets/img/en.png" alt="Art of Travel"></a></li>
                </ul>
            </div>
            <div class="container">
                <ul>
                    <li><span>Abonnez-vous</span></li>
                    <li><a href="https://www.facebook.com/aotagency/" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a href="https://www.youtube.com/channel/UC2gsJ0sQ1xG6rcsSWuDvECg" target="_blank"><i class="fab fa-youtube"></i></a></li>
                    <li><a href="https://www.instagram.com/aotagency/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                </ul>
            </div>
        </footer>
    </div>
</div>

<div class="sidebar-modal" [class.active]="classApplied">
    <div class="sidebar-modal-inner">
        <div class="about-area">
            <div>
                <a routerLink="#"><img src="assets/img/logo.jpg" alt="Art of Travel"></a>
            </div>
            <div class="title">
                <h2>A propos</h2>
                <p>Art of Travel est une agence de voyages et d'événements qui conçoit et organise tous vos voyages à travers le monde et vos événements culturels, sportifs et de divertissement en Tunisie. Art of Travel vous accompagne et vous permet de vivre pleinement votre voyage et d'assurer le succès de votre événement.
                </p>
            </div>
        </div>
        <div class="contact-area">
            <div class="contact-info">
                <div class="contact-info-content">
                    <h3>Contactez-nous par numéro de téléphone ou adresse e-mail</h3>
                    <h2>
                        <a href="tel:+21622259896">+216 22 25 98 96
                        </a>
                        <span>OU</span>
                        <a href="mailto:contact@aot-agency.com">contact@aot-agency.com</a>
                    </h2>
                    <ul class="social">
                        <li><a href="https://www.facebook.com/aotagency/" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="https://www.youtube.com/channel/UC2gsJ0sQ1xG6rcsSWuDvECg" target="_blank"><i class="fab fa-youtube"></i></a></li>
                        <li><a href="https://www.instagram.com/aotagency/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
        <span class="close-btn sidebar-modal-close-btn" (click)="toggleClass()"><i class="fas fa-times"></i></span>
    </div>
</div>
